<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="标题" prop="title">
                <a-input v-model="queryParam.title" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="消息编号" prop="number">
                <a-input v-model="queryParam.number" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="标题颜色" prop="titleColor">
                <a-select v-model="queryParam.titleColor" placeholder="请选择" allow-clear>
                  <a-select-option v-for="(item,key) in [{name:'红色',key:1},{name:'黑色',key:2}]" :key="key" :value="item.key">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="内容颜色" prop="contentColor">
                <a-select v-model="queryParam.contentColor" placeholder="请选择" allow-clear>
                  <a-select-option v-for="(item,key) in [{name:'红色',key:1},{name:'黑色',key:2}]" :key="key" :value="item.key">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="pic" slot-scope="text, record">
          <viewer :images="record.picPath ? record.picPath.split(',') : []">
            <img v-for="(item, key) in record.picPath ? record.picPath.split(',') : []" style="width:50px;height:50px;object-fit: cover;margin-right:5px" :src="item" :key="key">
          </viewer>
        </span>
        <span slot="titleColor" slot-scope="text, record">
          <a-tag color="#ff0000" v-if="record.titleColor == 1">红色</a-tag>
          <a-tag color="#000000" v-if="record.titleColor == 2">黑色</a-tag>
        </span>
        <span slot="contentColor" slot-scope="text, record">
          <a-tag color="#ff0000" v-if="record.contentColor == 1">红色</a-tag>
          <a-tag color="#000000" v-if="record.contentColor == 2">黑色</a-tag>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"

        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageinformation } from '@/api/valve/deviceLog'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'DeviceLog',
  components: {
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        title: null,
        number: null,
        titleColor: undefined,
        contentColor: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '消息编号',
          dataIndex: 'number',
          width: 180,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '图片',
          dataIndex: 'picPath',
          scopedSlots: { customRender: 'pic' },
          width: 100,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '标题',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '标题颜色',
          dataIndex: 'titleColor',
          scopedSlots: { customRender: 'titleColor' },
          width: 60,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '内容',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '内容颜色',
          dataIndex: 'contentColor',
          scopedSlots: { customRender: 'contentColor' },
          width: 60,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          width: 150,
          ellipsis: true,
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询型号信息列表 */
    getList () {
      this.loading = true
      pageinformation(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        title: null,
        number: null,
        titleColor: undefined,
        contentColor: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    }
  }
}
</script>
